import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    location: {
      project: null,
      builder: null,
      street: null,
      zip: null,
      town: null,
      terrainCategory: null,
      windloadzone: null,
    },
    geometry: {
      length: null,
      width: null,
      height: null,
    },
    system: {
      component: null,
      surface: null,
      ground: null,
      insulationmaterial: null,
      insulation: null,
      thickness: 0,
      system: null,
    },
    dowel: {
      anchorground: null,
      assembly: null,
      dowel: null,
      plate: null,
      plateName: null,
      plateAsset: null,
      freeLoadcapacity: null,
    },
    definitions: {},
    systemTree: {},
    dowelTree: {},
    globals: {},
    anchorgroundKey: null,
    systemChildren: {
      component: {},
      surface: {},
      ground: {},
      insulationmaterial: {},
      insulation: {},
    },
    dowelChildren: {
      anchorground: {},
      assembly: {},
      dowel: {},
      plate: {},
    },
  },
  getters: {
    getLocation: (state) => {
      return state.location;
    },
    getGeometry: (state) => {
      return state.geometry;
    },
    getSystem: (state) => {
      return state.system;
    },
    getDefinitions: (state) => {
      return state.definitions;
    },
    getSystemTree: (state) => {
      return state.systemTree;
    },
    getGlobals: (state) => {
      return state.globals;
    },
    getAnchorGroundKey: (state) => {
      return state.anchorgroundKey;
    },
    getDowel: (state) => {
      return state.dowel;
    },
    getDowelTree: (state) => {
      return state.dowelTree;
    },
    getSystemChildren: (state) => {
      return state.systemChildren;
    },
    getDowelChildren: (state) => {
      return state.dowelChildren;
    },
  },
  mutations: {
    updateLocation(state, location) {
      state.location = location;
    },
    updateGeometry(state, geometry) {
      state.geometry = geometry;
    },
    updateSystem(state, system) {
      state.system = system;
    },
    updateDowel(state, dowel) {
      state.dowel = dowel;
    },
    updateSystemChildren(state, systemChildren) {
      state.systemChildren = systemChildren;
    },
    updateDowelChildren(state, dowelChildren) {
      state.dowelChildren = dowelChildren;
    },
    setDefinitions(state, values) {
      state.definitions = values;
    },
    setSystemSelectTree(state, systemTree) {
      state.systemTree = systemTree;
    },
    setGlobals(state, data) {
      state.globals = data;
    },
    setAnchorGround(state, data) {
      state.anchorgroundKey = data;
    },
    setDowelSelectTree(state, dowelTree) {
      state.dowelTree = dowelTree;
    },
    resetDowel(state) {
      state.dowel = {
        anchorground: null,
        assembly: null,
        dowel: null,
        plate: null,
        freeLoadcapacity: null,
      };
      state.dowelChildren = {
        anchorground: {},
        assembly: {},
        dowel: {},
        plate: {},
      };
    },
  },
  actions: {
    async getDefinitions({ commit }) {
      axios
        .get(process.env.VUE_APP_PATH + "get/definitions/")
        .then((response) => {
          commit("setDefinitions", response.data);
        });
    },
    async getSelectTree({ commit }, type) {
      axios
        .get(process.env.VUE_APP_PATH + "get/data/" + type)
        .then((response) => {
          if (type === "system") {
            commit("setSystemSelectTree", response.data);
          }
        });
    },
    async getGlobals({ commit }) {
      axios.get(process.env.VUE_APP_PATH + "get/globals/").then((response) => {
        commit("setGlobals", response.data);
      });
    },
    //HIer wird dann der Untergrund mit rein übergeben
    async getAnchorGroundSelect({ commit }, params) {
      axios
        .get(
          process.env.VUE_APP_PATH +
            "get/anchorground/" +
            params[0] +
            "/" +
            params[1] +
            "/"
        )
        .then((response) => {
          commit("setAnchorGround", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getDowelSelectTree({ commit }, params) {
      axios
        .get(process.env.VUE_APP_PATH + "get/data/" + params.join("/"))
        .then((response) => {
          commit("setDowelSelectTree", response.data);
        });
    },
  },
  modules: {},
});
