<template>
  <div>
    <!-- <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      filename="Dübelrechner"
      :pdf-quality="2"
      paginate-elements-by-height="900"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="95%"
      :html-to-pdf-options="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <section class="pdf-item px-5">
          <h2 class="headline display-2 inline-block w-full my-5">
            Ihr Ergebnis - Knauf Dübelrechner
          </h2>
          <div class="geoTable mt-3">
            <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
              <span class="w-full">Objektdaten und Gebäudegeometrie</span>
            </div>
            <div class="flex py-5">
              <div class="w-full xl:w-1/3">
                <img
                  src="https://www.knauf.de/cutout/cutout_1598759_fullscreen_teaser_1.jpg"
                  class="w-full max-w-xs"
                />
              </div>
              <div class="w-full xl:w-1/3">
                <h3 class="font-bold">Objektdaten</h3>
                <div>
                  <div class="content-data">
                    <span class="footnote">Bezeichnung</span><br />{{
                      this.locationData.project
                    }}
                  </div>
                  <div class="content-data">
                    <span class="footnote">Bauherr</span><br />{{
                      this.locationData.builder
                    }}
                  </div>
                  <div class="content-data">
                    <span class="footnote">Straße</span><br />{{
                      this.locationData.street
                    }}
                  </div>
                  <div class="content-data">
                    <span class="footnote">PLZ/Ort</span><br />{{
                      this.locationData.zip
                    }}
                    {{ this.locationData.town }}
                  </div>
                </div>
              </div>
              <div class="w-full xl:w-1/3">
                <h3 class="font-bold">Gebäudegeometrie</h3>
                <div class="content-data">
                  <span class="footnote">Länge</span><br />{{
                    this.geoData.length
                  }}
                  m
                </div>
                <div class="content-data">
                  <span class="footnote">Breite</span><br />{{
                    this.geoData.width
                  }}
                  m
                </div>
                <div class="content-data">
                  <span class="footnote">Höhe</span><br />{{
                    this.geoData.height
                  }}
                  m
                </div>
              </div>
            </div>
          </div>
          <div class="windloadzoneTable">
            <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3 mb-10">
              <span class="w-full xl:w-1/4">Windlastzone</span
              ><span>{{ windloadzoneValue }} </span>
              <span class="font-normal pl-1">
                - {{ this.terrainCategoryText }}</span
              >
            </div>
            <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
              <span class="w-full">Fassadenbereiche</span>
            </div>
            <div class="knauf-table light-header" id="table-002">
              <div class="cell w-full xl:w-1/4 fixed-width">
                <div class="header">&nbsp;</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">Breite auf der Giebelseite</div>
                    <div class="content-data">Breite auf der Längsseite</div>
                  </div>
                </div>
              </div>
              <div class="cell">
                <div class="header">(Rand) Bereich A</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ borderZoneValue.a.gable }} m
                    </div>
                    <div class="content-data">
                      {{ borderZoneValue.a.long }} m
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell">
                <div class="header">(Mitte) Bereich B</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ borderZoneValue.b.gable }} m
                    </div>
                    <div class="content-data">
                      {{ borderZoneValue.b.long }} m
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="configError"
            class="configError"
            v-html="this.globals.configError.value"
          ></div>
          <div
            v-if="
              this.systemData.system.id === '61bafd27e1c05' &&
              this.systemData.insulationmaterial === 'im_1' &&
              !configError
            "
            class="configWarning"
          >
            Bei Gebäuden bis 8m Höhe + Haftzugfestigkeit ≥ 0,08 N/mm² + Windlast
            ≤ 1,0 kN/m² muss nicht gedübelt werden.
          </div>
          <div>
            <div v-if="this.dowelCount.dp2" class="dowelCountTable mt-5">
              <div
                v-for="(currentDowelCount, index) in this.dowelCount.dp2"
                :key="index"
              >
                <div class="w-full bg-light_grey font-bold flex p-2.5">
                  <span class="w-full"
                    >Dübelanzahl - {{ this.dowelTextData.assembly }}
                    {{ this.dowelCountHeaderNaming(currentDowelCount) }}</span
                  >
                </div>
                <div class="knauf-table light-header" id="table-dowelCount-001">
                  <div class="cell w-full xl:w-1/4 fixed-width">
                    <div class="header">&nbsp;</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">Windlast:</div>
                        <div class="content-data">Dübelanzahl:</div>
                      </div>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="header">(Rand) Bereich A</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">
                          {{ this.formatNumber(windload.randzoneA, "3") }} kN/m²
                        </div>
                        <div class="content-data">
                          {{ currentDowelCount.dowelcountA }} Dübel pro m²
                        </div>
                        <div class="content-data">
                          <img
                            :src="
                              this.dowelDiagramUrl(
                                currentDowelCount.dowelcountA_asset
                              )
                            "
                            class="w-full max-w-xs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="header">(Mitte) Bereich B</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">
                          {{ this.formatNumber(windload.randzoneB, "3") }} kN/m²
                        </div>
                        <div class="content-data">
                          {{ currentDowelCount.dowelcountB }} Dübel pro m²
                        </div>
                        <div class="content-data">
                          <img
                            :src="
                              this.dowelDiagramUrl(
                                currentDowelCount.dowelcountB_asset
                              )
                            "
                            class="w-full max-w-xs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.dowelCount.dp1" class="dowelCountTable mt-5">
              <div
                v-for="(currentDowelCount, index) in this.dowelCount.dp1"
                :key="index"
              >
                <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
                  <span class="w-full"
                    >Dübelanzahl - {{ this.dowelTextData.assembly
                    }}<span v-if="this.dowelData.assembly !== 'da_4'">
                      - Fläche</span
                    ></span
                  >
                </div>
                <div class="knauf-table light-header" id="table-dowelCount-002">
                  <div class="cell w-full xl:w-1/4 fixed-width">
                    <div class="header">&nbsp;</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">Windlast:</div>
                        <div class="content-data">Dübelanzahl:</div>
                      </div>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="header">(Rand) Bereich A</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">
                          {{ this.formatNumber(windload.randzoneA, "3") }} kN/m²
                        </div>
                        <div class="content-data">
                          {{ currentDowelCount.dowelcountA }} Dübel pro m²
                        </div>
                        <div class="content-data">
                          <img
                            :src="
                              this.dowelDiagramUrl(
                                currentDowelCount.dowelcountA_asset
                              )
                            "
                            class="w-full max-w-xs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="header">(Mitte) Bereich B</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">
                          {{ this.formatNumber(windload.randzoneB, "3") }} kN/m²
                        </div>
                        <div class="content-data">
                          {{ currentDowelCount.dowelcountB }} Dübel pro m²
                        </div>
                        <div class="content-data">
                          <img
                            :src="
                              this.dowelDiagramUrl(
                                currentDowelCount.dowelcountB_asset
                              )
                            "
                            class="w-full max-w-xs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.dowelCount.dp3" class="dowelCountTable mt-5">
              <div
                v-for="(currentDowelCount, index) in this.dowelCount.dp3"
                :key="index"
              >
                <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
                  <span class="w-full"
                    >Dübelanzahl - Holzrahmen -
                    {{ this.dowelTextData.assembly }} -</span
                  >
                </div>
                <div class="knauf-table light-header" id="table-dowelCount-002">
                  <div class="cell w-full xl:w-1/4 fixed-width">
                    <div class="header">&nbsp;</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">Windlast:</div>
                        <div class="content-data">Dübelanzahl:</div>
                      </div>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="header">(Rand) Bereich A</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">
                          {{ this.formatNumber(windload.randzoneA, "3") }} kN/m²
                        </div>
                        <div class="content-data">
                          {{ currentDowelCount.dowelcountA }} Dübel pro m²
                        </div>
                        <div class="content-data">
                          <img
                            :src="
                              this.dowelDiagramUrl(
                                currentDowelCount.dowelcountA_asset
                              )
                            "
                            class="w-full max-w-xs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="header">(Mitte) Bereich B</div>
                    <div class="content">
                      <div class="scrollerWrapper">
                        <div class="content-data">
                          {{ this.formatNumber(windload.randzoneB, "3") }} kN/m²
                        </div>
                        <div class="content-data">
                          {{ currentDowelCount.dowelcountB }} Dübel pro m²
                        </div>
                        <div class="content-data">
                          <img
                            :src="
                              this.dowelDiagramUrl(
                                currentDowelCount.dowelcountB_asset
                              )
                            "
                            class="w-full max-w-xs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="systemTable mb-5">
            <div
              class="w-full bg-light_grey font-bold flex p-2.5 mt-3 relative"
            >
              <span class="w-full">Systemdaten</span>
              <a
                v-if="this.systemData.system.productlink !== ''"
                :href="this.systemData.system.productlink"
                target="_blank"
                class="absolute right-0.5 top-0.5 btnStyle"
              >
                mehr zum System
              </a>
            </div>
            <p class="p-2 pt-0">
              <span class="footnote w-full">Gewähltes System</span>
              {{ this.systemData.system.approval }} -
              {{ this.systemData.system.name }}
            </p>
            <div class="knauf-table light-header" id="table-system">
              <div class="cell">
                <div class="header">Oberflächenbeschichtung</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.systemTextData.surface }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell">
                <div class="header">Untergrund</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.systemTextData.ground }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell">
                <div class="header">Dämmstoff</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.systemTextData.insulation }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell">
                <div class="header">Dämmstoffdicke</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.systemTextData.thickness }} mm
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="dowelTable mb-10">
            <div
              class="w-full bg-light_grey font-bold flex p-2.5 mt-3 relative"
            >
              <span class="w-full xl:w-1/4">Dübeldaten</span>
              <a
                v-if="this.dowelTextData.productlink !== ''"
                :href="this.dowelTextData.productlink"
                target="_blank"
                class="absolute right-0.5 top-0.5 btnStyle"
              >
                mehr zum Dübel
              </a>
            </div>
            <p class="p-2 pt-0">
              <span class="footnote w-full">Gewählter Dübel</span>
              {{ this.dowelTextData.dowel }}
            </p>
            <div class="knauf-table light-header" id="table-dowel1">
              <div class="cell">
                <div class="header">Verankerungsgrund</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.dowelTextData.anchorground }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell">
                <div class="header">Dübelmontage</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.dowelTextData.assembly }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell" v-if="this.dowelData.plateName">
                <div class="header">{{ this.dowelTextData.plate }}</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.dowelData.plateName }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell" v-else>
                <div class="header">Dübelteller</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{ this.dowelTextData.plate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell">
                <div class="header">
                  Dübeltragfähigkeit
                  <span
                    v-if="
                      this.dowelData.dowel !== '62179310b196e' &&
                      this.dowelData.dowel !== '6217932414e20'
                    "
                    >N<sub>Rk</sub></span
                  ><span v-else>F<sub>ax,90,Rd</sub></span>
                </div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div class="content-data">
                      {{
                        this.dowelloadcapacity == 999
                          ? "-"
                          : this.formatNumber(this.dowelloadcapacity, "2") +
                            " kN"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="knauf-table light-header" id="table-dowel2">
              <div class="cell">
                <div
                  class="header"
                  v-if="this.dowelData.dowel === '61d5a3e5ea72f'"
                >
                  Toleranzausgleich Neubau
                </div>
                <div class="header" v-else>
                  Dübellänge
                  <span v-if="this.dowelLength.oldBuilding !== 0"> Neubau</span>
                </div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div
                      class="content-data"
                      v-if="this.dowelData.dowel === '61d5a3e5ea72f'"
                    >
                      0 – 10 mm
                    </div>
                    <div class="content-data" v-else>
                      {{ this.dowelLength.newBuilding }} mm
                    </div>
                    <div
                      class="content-data"
                      v-if="this.dowelData.dowel === '61d5a3e5ea72f'"
                    >
                      Dübellänge unabhängig der Dämmstoffdicke
                    </div>
                    <div
                      class="content-data"
                      v-else-if="
                        this.systemData.system.id === '61bafba30283a' ||
                        this.systemData.system.id === '61bafc2f5747a' ||
                        this.systemData.system.id === '61c1a05badd85'
                      "
                    >
                      Verankerungstiefe + Dämmstoffdicke
                      <span v-if="this.systemData.ground === 'g_2'">
                        <br />Achtung: Ohne Berücksichtigung der
                        Plattendicke</span
                      >
                    </div>
                    <div
                      class="content-data"
                      v-else-if="
                        this.systemData.system.id === '61bafabd4ffcf' &&
                        this.dowelData.anchorground === 'g_2'
                      "
                    >
                      Verankerungstiefe + 10 mm Durchdringung + 5 mm Kleberdicke
                      + Dämmstoffdicke
                    </div>
                    <div
                      class="content-data"
                      v-else-if="
                        this.systemData.system.id === '61bafabd4ffcf' &&
                        this.systemData.ground === 'g_3'
                      "
                    >
                      Verankerungstiefe + 5 mm Kleberdicke + Dämmstoffdicke
                    </div>
                    <div class="content-data" v-else>
                      Verankerungstiefe + 5 mm Kleberdicke + 5 mm
                      Toleranzausgleich + Dämmstoffdicke
                    </div>
                  </div>
                </div>
              </div>
              <div class="cell" v-if="this.dowelLength.oldBuilding !== 0">
                <div
                  class="header"
                  v-if="this.dowelData.dowel === '61d5a3e5ea72f'"
                >
                  Toleranzausgleich Altbau
                </div>
                <div class="header" v-else>Dübellänge Altbau</div>
                <div class="content">
                  <div class="scrollerWrapper">
                    <div
                      class="content-data"
                      v-if="this.dowelData.dowel === '61d5a3e5ea72f'"
                    >
                      10 – 30 mm oder 30 - 60 mm
                    </div>
                    <div class="content-data" v-else>
                      {{ this.dowelLength.oldBuilding }} mm
                    </div>
                    <div
                      class="content-data"
                      v-if="this.dowelData.dowel === '61d5a3e5ea72f'"
                    >
                      &nbsp;
                    </div>
                    <div class="content-data" v-else>
                      Verankerungstiefe + 5 mm Kleberdicke + 5 mm
                      Toleranzausgleich + Dämmstoffdicke + 20mm Altputz
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              v-if="
                (this.systemData.system.id === '61bafba30283a' ||
                  this.systemData.system.id === '61bafc2f5747a' ||
                  this.systemData.system.id === '61c1a05badd85') &&
                this.systemData.ground === 'g_2'
              "
              class="configWarning"
            >
              Achtung: Dübellänge ohne Berücksichtigung der Plattendicke</span
            >
          </div>
          <p class="footnote" v-html="this.globals.legalText.value"></p>
        </section>
      </template>
    </vue3-html2pdf> -->
    <div class="mobilTabNavigation">
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab1', type: 'back' })"
      >
        <span class="headline number">1</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Standortdaten</span>
      </a>
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab2', type: 'back' })"
      >
        <span class="headline number">2</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Gebäudegeometrie</span>
      </a>
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab3', type: 'back' })"
      >
        <span class="headline number">3</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Systemauswahl</span>
      </a>
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab4', type: 'back' })"
      >
        <span class="headline number">4</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Dübelauswahl</span>
      </a>
      <a href="#" class="tabLink active">
        <span class="headline number">5</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Ergebnis</span>
      </a>
    </div>
    <h2 class="headline display-2">Ihr Ergebnis</h2>
    <div class="geoTable">
      <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
        <span class="w-full">Objektdaten und Gebäudegeometrie</span>
      </div>
      <div class="flex py-5">
        <div class="w-full xl:w-1/3 px-5">
          <img :src="`${imgFolder}hausSkizze-inkl-randzonen.svg`" />
        </div>
        <div class="w-full xl:w-1/3">
          <h3 class="font-bold">Objektdaten</h3>
          <div>
            <div class="content-data">
              <span class="footnote">Bezeichnung</span><br />{{
                this.locationData.project
              }}
            </div>
            <div class="content-data">
              <span class="footnote">Bauherr</span><br />{{ this.locationData.builder }}
            </div>
            <div class="content-data">
              <span class="footnote">Straße</span><br />{{ this.locationData.street }}
            </div>
            <div class="content-data">
              <span class="footnote">PLZ/Ort</span><br />{{ this.locationData.zip }}
              {{ this.locationData.town }}
            </div>
          </div>
        </div>
        <div class="w-full xl:w-1/3">
          <h3 class="font-bold">Gebäudegeometrie</h3>
          <div class="content-data">
            <span class="footnote">Länge</span><br />{{ this.geoData.length }}
            m
          </div>
          <div class="content-data">
            <span class="footnote">Breite</span><br />{{ this.geoData.width }}
            m
          </div>
          <div class="content-data">
            <span class="footnote">Höhe</span><br />{{ this.geoData.height }} m
          </div>
        </div>
      </div>
    </div>
    <div class="windloadzoneTable mb-5">
      <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
        <span class="w-full xl:w-1/4">Windlastzone</span
        ><span>{{ windloadzoneValue }} </span>
        <span class="font-normal pl-1"> - {{ this.terrainCategoryText }}</span>
      </div>
      <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
        <span class="w-full">Fassadenbereiche</span>
      </div>
      <div class="knauf-table light-header" id="table-002">
        <div class="cell w-full xl:w-1/4 fixed-width">
          <div class="header">&nbsp;</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">Breite auf der Giebelseite</div>
              <div class="content-data">Breite auf der Längsseite</div>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="header">(Rand) Bereich A</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">{{ borderZoneValue.a.gable }} m</div>
              <div class="content-data">{{ borderZoneValue.a.long }} m</div>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="header">(Mitte) Bereich B</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">{{ borderZoneValue.b.gable }} m</div>
              <div class="content-data">{{ borderZoneValue.b.long }} m</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="configError"
      class="configError"
      v-html="this.globals.configError.value"
    ></div>
    <div
      v-if="
        this.systemData.system.id === '61bafd27e1c05' &&
        this.systemData.insulationmaterial === 'im_1' &&
        !configError &&
        this.systemData.thickness < 200
      "
      class="configWarning"
    >
      Bei Gebäuden bis 8 m Höhe + Haftzugfestigkeit ≥ 0,08 N/mm² + Windlast ≤ 1,0 kN/m²
      muss nicht gedübelt werden.
    </div>
    <div
      v-if="this.systemData.component === 'co_2' && !configError"
      class="configWarning"
    >
      Die Ergebnisse gelten nicht für Durchfahrten, Durchgänge, aufgeständerte Gebäude und
      Passagen.
    </div>
    <div v-if="this.dowelCount.dp2" class="dowelCountTable">
      <div v-for="(currentDowelCount, index) in this.dowelCount.dp2" :key="index">
        <div class="w-full bg-light_grey font-bold flex p-2.5">
          <span class="w-full"
            >Dübelanzahl - {{ this.dowelTextData.assembly }}
            {{ this.dowelCountHeaderNaming(currentDowelCount) }}</span
          >
        </div>
        <span v-if="currentDowelCount.specials" class="configWarning"
          >Hinweis: Gilt nur bei
          {{ this.getSpecialTexts(currentDowelCount.specials) }}
        </span>
        <div class="knauf-table light-header" id="table-dowelCount-001">
          <div class="cell w-full xl:w-1/4 fixed-width myBorder">
            <div class="header">&nbsp;</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">Windlast:</div>
                <div class="content-data">Dübelanzahl:</div>
              </div>
            </div>
          </div>
          <div class="cell">
            <div class="header">(Rand) Bereich A</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">
                  {{ this.formatNumber(windload.randzoneA, "3") }} kN/m²
                </div>
                <div class="content-data">
                  {{ currentDowelCount.dowelcountA }} Dübel pro m²
                </div>
                <div class="content-data">
                  <img
                    :src="this.dowelDiagramUrl(currentDowelCount.dowelcountA_asset)"
                    class="w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="cell">
            <div class="header">(Mitte) Bereich B</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">
                  {{ this.formatNumber(windload.randzoneB, "3") }} kN/m²
                </div>
                <div class="content-data">
                  {{ currentDowelCount.dowelcountB }} Dübel pro m²
                </div>
                <div class="content-data">
                  <img
                    :src="this.dowelDiagramUrl(currentDowelCount.dowelcountB_asset)"
                    class="w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.dowelCount.dp1" class="dowelCountTable">
      <div v-for="(currentDowelCount, index) in this.dowelCount.dp1" :key="index">
        <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
          <span class="w-full"
            >Dübelanzahl - {{ this.dowelTextData.assembly
            }}<span v-if="this.dowelData.assembly !== 'da_4'"> - Fläche</span></span
          >
        </div>
        <span v-if="currentDowelCount.specials" class="configWarning"
          >Hinweis: Gilt nur bei
          {{ this.getSpecialTexts(currentDowelCount.specials) }}
        </span>
        <div class="knauf-table light-header" id="table-dowelCount-002">
          <div class="cell w-full xl:w-1/4 fixed-width myBorder">
            <div class="header">&nbsp;</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">Windlast:</div>
                <div class="content-data">Dübelanzahl:</div>
              </div>
            </div>
          </div>
          <div class="cell">
            <div class="header">(Rand) Bereich A</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">
                  {{ this.formatNumber(windload.randzoneA, "3") }} kN/m²
                </div>
                <div class="content-data">
                  {{ currentDowelCount.dowelcountA }} Dübel pro m²
                </div>
                <div class="content-data">
                  <img
                    :src="this.dowelDiagramUrl(currentDowelCount.dowelcountA_asset)"
                    class="w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="cell">
            <div class="header">(Mitte) Bereich B</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">
                  {{ this.formatNumber(windload.randzoneB, "3") }} kN/m²
                </div>
                <div class="content-data">
                  {{ currentDowelCount.dowelcountB }} Dübel pro m²
                </div>
                <div class="content-data">
                  <img
                    :src="this.dowelDiagramUrl(currentDowelCount.dowelcountB_asset)"
                    class="w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.dowelCount.dp3" class="dowelCountTable">
      <div v-for="(currentDowelCount, index) in this.dowelCount.dp3" :key="index">
        <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3">
          <span class="w-full"
            >Dübelanzahl - Holzrahmen - {{ this.dowelTextData.assembly }} -</span
          >
        </div>
        <div class="knauf-table light-header" id="table-dowelCount-002">
          <div class="cell w-full xl:w-1/4 fixed-width myBorder">
            <div class="header">&nbsp;</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">Windlast:</div>
                <div class="content-data">Dübelanzahl:</div>
              </div>
            </div>
          </div>
          <div class="cell">
            <div class="header">(Rand) Bereich A</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">
                  {{ this.formatNumber(windload.randzoneA, "3") }} kN/m²
                </div>
                <div class="content-data">
                  {{ currentDowelCount.dowelcountA }} Dübel pro m²
                </div>
                <div class="content-data">
                  <img
                    :src="this.dowelDiagramUrl(currentDowelCount.dowelcountA_asset)"
                    class="w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="cell">
            <div class="header">(Mitte) Bereich B</div>
            <div class="content">
              <div class="scrollerWrapper">
                <div class="content-data">
                  {{ this.formatNumber(windload.randzoneB, "3") }} kN/m²
                </div>
                <div class="content-data">
                  {{ currentDowelCount.dowelcountB }} Dübel pro m²
                </div>
                <div class="content-data">
                  <img
                    :src="this.dowelDiagramUrl(currentDowelCount.dowelcountB_asset)"
                    class="w-full max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="systemTable mb-5">
      <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3 relative">
        <span class="w-full xl:w-1/4">Systemdaten</span>
        <a
          v-if="this.systemData.system.productlink !== ''"
          :href="this.systemData.system.productlink"
          target="_blank"
          class="absolute right-0.5 top-0.5 btnStyle"
        >
          mehr zum System
        </a>
      </div>
      <p class="p-2 pt-0">
        <span class="footnote w-full">Gewähltes System</span>
        {{ this.systemData.system.approval }} -
        {{ this.systemData.system.name }}
      </p>
      <div class="knauf-table light-header" id="table-system">
        <div class="cell">
          <div class="header">Oberflächenbeschichtung</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">
                {{ this.systemTextData.component }} - {{ this.systemTextData.surface }}
              </div>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="header">Untergrund</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">{{ this.systemTextData.ground }}</div>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="header">Dämmstoff</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">
                {{ this.systemTextData.insulation }}
              </div>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="header">Dämmstoffdicke</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">{{ this.systemTextData.thickness }} mm</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dowelTable mb-5">
      <div class="w-full bg-light_grey font-bold flex p-2.5 mt-3 relative">
        <span class="w-full xl:w-1/4">Dübeldaten</span>
        <a
          v-if="this.dowelTextData.productlink !== ''"
          :href="this.dowelTextData.productlink"
          target="_blank"
          class="absolute right-0.5 top-0.5 btnStyle"
        >
          mehr zum Dübel
        </a>
      </div>
      <span
        v-if="
          this.systemData.system.id === '61bafd27e1c05' &&
          (this.systemData.insulation === 'in_1' ||
            this.systemData.insulation === 'in_2' ||
            this.systemData.insulation === 'in_3' ||
            this.systemData.insulation === 'in_4' ||
            this.systemData.insulation === 'in_5' ||
            this.systemData.insulation === 'in_6') &&
          this.dowelData.dowel !== '61d5a3a691ff4'
        "
        class="configWarning"
      >
        Bei einem Systemgewicht > 75 kg/m² oder einer Dämmstoffdicke > 200 mm ist nur der
        Schraubdübel STR U 2G zugelassen.</span
      >
      <span
        v-if="
          this.systemData.system.id === '61bafd27e1c05' &&
          this.systemData.insulation === 'in_15' &&
          this.dowelData.dowel !== '61d5a3a691ff4'
        "
        class="configWarning"
      >
        Bei einem Systemgewicht > 75 kg/m² ist nur der Schraubdübel STR U 2G
        zugelassen.</span
      >
      <p class="p-2 pt-0">
        <span class="footnote w-full">Gewählter Dübel</span>
        {{ this.dowelTextData.dowel }}
      </p>
      <div class="knauf-table light-header" id="table-dowel1">
        <div class="cell">
          <div class="header">Verankerungsgrund</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">
                {{ this.dowelTextData.anchorground }}
              </div>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="header">Dübelmontage</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">{{ this.dowelTextData.assembly }}</div>
            </div>
          </div>
        </div>
        <div class="cell" v-if="this.dowelData.plateName">
          <div class="header">{{ this.dowelTextData.plate }}</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">
                {{ this.dowelData.plateName }}
              </div>
            </div>
          </div>
        </div>
        <div class="cell" v-else>
          <div class="header">Dübelteller</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">
                {{ this.dowelTextData.plate }}
              </div>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="header">
            Dübeltragfähigkeit
            <span
              v-if="
                this.dowelData.dowel !== '62179310b196e' &&
                this.dowelData.dowel !== '6217932414e20'
              "
              >N<sub>Rk</sub></span
            ><span v-else>F<sub>ax,90,Rd</sub></span>
          </div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data">
                {{
                  this.dowelloadcapacity == 999
                    ? "-"
                    : this.formatNumber(this.dowelloadcapacity, "2") + " kN"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="knauf-table light-header" id="table-dowel2">
        <div class="cell">
          <div class="header" v-if="this.dowelData.dowel === '61d5a3e5ea72f'">
            Toleranzausgleich Neubau
          </div>
          <div class="header" v-else>
            Dübellänge
            <span v-if="this.dowelLength.oldBuilding !== 0"> Neubau</span>
          </div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data" v-if="this.dowelData.dowel === '61d5a3e5ea72f'">
                0 – 10 mm
              </div>
              <div class="content-data" v-else>{{ this.dowelLength.newBuilding }} mm</div>
              <div class="content-data" v-if="this.dowelData.dowel === '61d5a3e5ea72f'">
                Dübellänge unabhängig der Dämmstoffdicke
              </div>
              <div
                class="content-data"
                v-else-if="
                  this.systemData.system.id === '61bafba30283a' ||
                  this.systemData.system.id === '61bafc2f5747a' ||
                  this.systemData.system.id === '61c1a05badd85'
                "
              >
                Mindestverankerungstiefe + Dämmstoffdicke
                <span v-if="this.systemData.ground === 'g_2'">
                  <br />Achtung: Ohne Berücksichtigung der Plattendicke</span
                >
              </div>
              <div
                class="content-data"
                v-else-if="
                  this.systemData.system.id === '61bafabd4ffcf' &&
                  this.dowelData.anchorground === 'g_2'
                "
              >
              Mindestverankerungstiefe + 10 mm Durchdringung + 5 mm Kleberdicke +
                Dämmstoffdicke
              </div>
              <div
                class="content-data"
                v-else-if="
                  this.systemData.system.id === '61bafabd4ffcf' &&
                  this.systemData.ground === 'g_3'
                "
              >
              Mindestverankerungstiefe + 5 mm Kleberdicke + Dämmstoffdicke
              </div>
              <div class="content-data" v-else>
                Mindestverankerungstiefe + 5 mm Kleberdicke + 5 mm Toleranzausgleich +
                Dämmstoffdicke
              </div>
            </div>
          </div>
        </div>
        <div class="cell" v-if="this.dowelLength.oldBuilding !== 0">
          <div class="header" v-if="this.dowelData.dowel === '61d5a3e5ea72f'">
            Toleranzausgleich Altbau
          </div>
          <div class="header" v-else>Dübellänge Altbau</div>
          <div class="content">
            <div class="scrollerWrapper">
              <div class="content-data" v-if="this.dowelData.dowel === '61d5a3e5ea72f'">
                10 – 30 mm oder 30 - 60 mm
              </div>
              <div class="content-data" v-else>{{ this.dowelLength.oldBuilding }} mm</div>
              <div class="content-data" v-if="this.dowelData.dowel === '61d5a3e5ea72f'">
                &nbsp;
              </div>
              <div class="content-data" v-else>
                Mindestverankerungstiefe + 5 mm Kleberdicke + 5 mm Toleranzausgleich +
                Dämmstoffdicke + 20mm Altputz
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="footnote" v-html="this.globals.legalText.value"></p>
    <div class="w-full btnWrapper">
      <!-- <button @click="generateReport()" class="printBtn">PDF Download</button> -->
      <form id="pdfForm" method="post" :action="this.pdfFolder">
        <input type="hidden" name="resultValues" id="resultValues" value="" />
        <button @click="generatePDF()" class="printBtn">PDF Download</button>
      </form>
    </div>
    <div class="w-full btnWrapper">
      <button
        @click="$emit('changeTab', { currentTab: 'tab4', type: 'back' })"
        class="prevBtn"
      >
        Zurück
      </button>
    </div>
  </div>
</template>

<script>
// import Vue3Html2pdf from "vue3-html2pdf";

export default {
  components: {
    // Vue3Html2pdf,
  },
  data() {
    return {
      configError: false,
      selectValues: {},
      systemData: {},
      globals: {},
      dowelData: {},
      dowelLength: {},
      locationData: {},
      geoData: {},
      dowelCount: {
        dp1: [
          {
            dowelcountA: null,
            dowelcountA_asset: null,
            dowelcountB: null,
            dowelcountB_asset: null,
            plasterweight: null,
          },
        ],
        dp2: [
          {
            dowelcountA: null,
            dowelcountA_asset: null,
            dowelcountB: null,
            dowelcountB_asset: null,
            plasterweight: null,
          },
        ],
      },
      windload: {
        randzoneA: null,
        randzoneb: null,
      },
      dowelloadcapacity: null,
      borderzone: {
        a: {
          gable: null,
          long: null,
        },
        b: {
          gable: null,
          long: null,
        },
      },
      imgFolder: process.env.VUE_APP_FRONTEND_PATH + "images/",
      pdfFolder: process.env.VUE_APP_PATH + "get/resultpdf",
      // htmlToPdfOptions: {
      //   margin: [0.4, 0, 0.7, 0],
      //   filename: "duebelrechner.pdf",
      //   enableLinks: true,
      //   image: {
      //     type: "jpeg",
      //     quality: 1,
      //   },
      // html2canvas: {
      //   scale: 1,
      //   useCORS: true,
      // },
      // jsPDF: {
      //   unit: "cm",
      //   format: "a4",
      //   orientation: "portrait",
      // },
      // },
    };
  },
  computed: {
    windloadzoneValue() {
      return this.locationData.windloadzone;
    },
    borderZoneValue() {
      return this.borderzone;
    },
    dowelTextData() {
      const assemblyText = this.findInList(
        this.selectValues.assembly.values,
        "value",
        this.dowelData.assembly
      );
      const dowelText = this.findInList(
        this.selectValues.dowel.values,
        "value",
        this.dowelData.dowel
      );
      let plateText = this.findInList(
        this.selectValues.plate.values,
        "value",
        this.dowelData.plate
      );
      if (plateText !== undefined) {
        plateText = plateText.label;
      } else {
        plateText = "-";
      }
      let anchorgroundText = null;
      if (this.dowelData.anchorground.substring(0, 3) === "abm") {
        anchorgroundText = this.findInList(
          this.selectValues["anchor_base_massiv"].values,
          "value",
          this.dowelData.anchorground
        );
      }
      if (this.dowelData.anchorground.substring(0, 4) === "abwm") {
        anchorgroundText = this.findInList(
          this.selectValues["anchor_base_wood_mw_solidwood"].values,
          "value",
          this.dowelData.anchorground
        );
        if (anchorgroundText == undefined) {
          anchorgroundText = this.findInList(
            this.selectValues["anchor_base_wood_mw_plateunderground"].values,
            "value",
            this.dowelData.anchorground
          );
        }
      }
      if (this.dowelData.anchorground.substring(0, 4) === "abwf") {
        anchorgroundText = this.findInList(
          this.selectValues["anchor_base_wood_fiber"].values,
          "value",
          this.dowelData.anchorground
        );
      }
      if (this.dowelData.anchorground === "unknown") {
        anchorgroundText = this.findInList(
          this.selectValues["anchor_base_massiv"].values,
          "value",
          this.dowelData.anchorground
        );
      }
      const values = {
        assembly: assemblyText.label,
        dowel: dowelText.label,
        plate: plateText,
        anchorground: anchorgroundText.label,
        productlink: dowelText.productlink,
        downloadlink: dowelText.downloadlink,
      };
      return values;
    },
    systemTextData() {
      const groundText = this.findInList(
        this.selectValues.ground.values,
        "value",
        this.systemData.ground
      );
      const surfaceText = this.findInList(
        this.selectValues.surface.values,
        "value",
        this.systemData.surface
      );
      const componentText = this.findInList(
        this.selectValues.component.values,
        "value",
        this.systemData.component
      );
      const insulationText = this.findInList(
        this.selectValues.insulation.values,
        "value",
        this.systemData.insulation
      );
      const values = {
        component: componentText.label,
        ground: groundText.label,
        surface: surfaceText.label,
        insulation: insulationText.label,
        thickness: this.systemData.thickness,
      };
      return values;
    },
    terrainCategoryText() {
      let terrainText = "";
      if (this.locationData.terrainCategory == null) {
        terrainText = "Binnenland";
      }
      if (this.locationData.terrainCategory == 1) {
        if (this.locationData.windloadzone === "4") {
          terrainText = "Küste der Nord- und Ostsee und Inseln der Ostsee";
        } else {
          terrainText = "Küste und Inseln der Ostsee";
        }
      }
      if (this.locationData.terrainCategory == 2) {
        terrainText = "Inseln der Nordsee";
      }
      return terrainText;
    },
  },
  created() {
    this.globals = this.$store.getters.getGlobals;
    this.selectValues = this.$store.getters.getDefinitions;
    this.systemData = this.$store.getters.getSystem;
    this.dowelData = this.$store.getters.getDowel;
    this.locationData = this.$store.getters.getLocation;
    this.geoData = this.$store.getters.getGeometry;
    this.getWindLoad();
    this.getDowelLength();
    this.calcBorderZone();
  },
  methods: {
    calcBorderZone() {
      const e =
        this.geoData.height * 2 < this.geoData.length
          ? this.geoData.height * 2
          : this.geoData.length;
      const f =
        this.geoData.height * 2 < this.geoData.width
          ? this.geoData.height * 2
          : this.geoData.width;
      this.borderzone.a.gable = Math.round((1 / 5) * e * 100) / 100;
      this.borderzone.a.long = Math.round((1 / 5) * f * 100) / 100;
      this.borderzone.b.gable =
        Math.round((this.geoData.width - this.borderzone.a.gable * 2) * 100) / 100;
      this.borderzone.b.long =
        Math.round((this.geoData.length - this.borderzone.a.long * 2) * 100) / 100;
      //Rand kann nicht größer als die Seite sein (Rand Giebel kann nicht Größer Breite sein)
      if (this.borderzone.a.gable * 2 > this.geoData.width) {
        this.borderzone.a.gable = this.geoData.width / 2;
      }
      if (this.borderzone.a.long * 2 > this.geoData.length) {
        this.borderzone.a.long = this.geoData.length / 2;
      }
      //Mitte kann nicht kleiner 0 sein
      if (this.borderzone.b.gable < 0) {
        this.borderzone.b.gable = 0;
      }
      if (this.borderzone.b.long < 0) {
        this.borderzone.b.long = 0;
      }
    },
    getDowelloadcapacity() {
      if (this.dowelData.anchorground === "unknown") {
        this.dowelloadcapacity = this.dowelData.freeLoadcapacity;
        this.getDowelCount();
      } else {
        fetch(
          process.env.VUE_APP_PATH +
            "get/dowelloadcapacity/" +
            this.dowelData.dowel +
            "/" +
            this.dowelData.anchorground
        )
          .then((response) => response.json())
          .then((responseJSON) => {
            this.dowelloadcapacity = responseJSON.dowelloadcapacity;
            this.getDowelCount();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getDowelCount() {
      let plate = "";
      if (this.dowelData.plate !== "" && this.dowelData.plate !== "p_1") {
        plate = "-" + this.dowelData.plate.replace("_", "");
      }
      fetch(
        process.env.VUE_APP_PATH +
          "get/dowelcount/" +
          this.systemData.system.id +
          "/" +
          this.systemData.ground.replace("_", "-") +
          "/" +
          this.dowelData.assembly.replace("_", "-") +
          "/" +
          this.dowelData.dowel +
          plate +
          "/" +
          this.systemData.insulation.replace("_", "-") +
          "/" +
          this.systemData.thickness +
          "/" +
          this.dowelloadcapacity +
          "/" +
          this.windload.randzoneA +
          "/" +
          this.windload.randzoneB
      )
        .then((response) => response.json())
        .then((responseJSON) => {
          this.dowelCount = responseJSON;
          if (this.dowelCount.length === 0) {
            this.configError = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getDowelLength() {
      fetch(
        process.env.VUE_APP_PATH +
          "get/dowellength/" +
          this.systemData.system.id +
          "/" +
          this.dowelData.dowel +
          "/" +
          this.dowelData.anchorground +
          "/" +
          this.systemData.thickness
      )
        .then((response) => response.json())
        .then((responseJSON) => {
          this.dowelLength = responseJSON;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getWindLoad() {
      let heightHelper = "";
      if (this.geoData.height <= 10) {
        heightHelper = "hh1";
      } else if (this.geoData.height <= 18) {
        heightHelper = "hh2";
      } else {
        heightHelper = "hh3";
      }
      let terrain = "";
      if (this.locationData.terrainCategory !== null) {
        terrain = "-" + this.locationData.terrainCategory;
      }
      fetch(
        process.env.VUE_APP_PATH +
          "get/windload/wz" +
          this.windloadzoneValue +
          terrain +
          "/" +
          heightHelper
      )
        .then((response) => response.json())
        .then((responseJSON) => {
          this.windload = responseJSON;
          this.getDowelloadcapacity();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    findInList(source, sourceKey, id) {
      for (const key in source) {
        const item = source[key];
        if (item[sourceKey] === id) {
          return item;
        }
        if (item.children) {
          const subresult = this.findInList(item.children, sourceKey, id);
          if (subresult) {
            return subresult;
          }
        }
      }
    },
    dowelDiagramUrl(id) {
      let url = "";
      if (this.globals.mdbLink) {
        url = this.globals.mdbLink;
        url = url.replace("###id###", id);
      }
      return url;
    },
    dowelCountHeaderNaming(item) {
      let headline = "";
      // sf_1: Putzsystem < 10 kg/m²
      // sf_3: Putzsystem > 10 kg/m²
      // sg_1: Systemgewicht ≤ 100 kg/m²
      // sg_2: Systemgewicht ≤ 75 kg/m²
      if (this.dowelData.assembly !== "da_4") {
        headline = " - Fläche + Fuge";
      }
      if (item.plasterweight !== null && item.plasterweight.indexOf("sf") !== -1) {
        headline = "- " + this.globals[item.plasterweight];
      } else if (item.plasterweight !== null && item.plasterweight.indexOf("sg") !== -1) {
        headline = "- " + this.globals[item.plasterweight];
      }
      return headline;
    },
    getSpecialTexts(specials) {
      const specialsArray = specials.replace(/\s/g, "").split(",");
      let specialText = "";
      for (let i = 0, l = specialsArray.length; i < l; i += 1) {
        if (i !== 0) {
          specialText += " + ";
        }
        specialText += this.globals[specialsArray[i]];
      }
      return specialText;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    generatePDF() {
      let resultValues = {};
      resultValues.locationData = Object.assign({}, this.locationData);
      resultValues.geoData = Object.assign({}, this.geoData);
      resultValues.borderzone = Object.assign({}, this.borderzone);
      resultValues.dowelCount = Object.assign({}, this.dowelCount);
      resultValues.windload = Object.assign({}, this.windload);
      resultValues.windloadzoneValue = Object.assign({}, this.windloadzoneValue);
      resultValues.dowelData = Object.assign({}, this.dowelData);
      resultValues.dowelTextData = Object.assign({}, this.dowelTextData);
      resultValues.dowelLength = Object.assign({}, this.dowelLength);
      resultValues.dowelloadcapacity = this.dowelloadcapacity;
      resultValues.borderZoneValue = this.borderZoneValue;
      resultValues.systemTextData = Object.assign({}, this.systemTextData);
      resultValues.systemData = Object.assign({}, this.systemData);
      resultValues.terrainCategoryText = this.terrainCategoryText;

      if (this.configError) {
        resultValues.configError = 1;
      } else {
        resultValues.configError = 0;
      }
      resultValues.globals = Object.assign({}, this.globals);

      const json = JSON.stringify(resultValues);
      document.getElementById("resultValues").value = json;
      document.getElementById("pdfForm").submit();
      return false;
    },
    formatNumber(_number, _decimals) {
      let numberString = _number + "";
      if (
        numberString.indexOf(".") != "-1" &&
        numberString.indexOf(".") == numberString.length - _decimals
      ) {
        numberString = numberString + "0";
      } else if (numberString.indexOf(".") == "-1") {
        if (_decimals == 3) {
          numberString = numberString + ".000";
        } else {
          numberString = numberString + ".00";
        }
      }
      return numberString;
    },
  },
};
</script>

<style></style>
