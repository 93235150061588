<template>
  <div>
    <div class="mobilTabNavigation">
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab1', type: 'back' })"
      >
        <span class="headline number">1</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Standortdaten</span>
      </a>
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab2', type: 'back' })"
      >
        <span class="headline number">2</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Gebäudegeometrie</span>
      </a>
      <a href="#" class="tabLink active">
        <span class="headline number">3</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Systemauswahl</span>
      </a>
    </div>
    <h2 class="headline display-2">Finden sie ihr System</h2>
    <div class="formGrid">
      <div class="w-full xl:w-1/3">
        <img
          :src="
            this.systemData.asset === undefined
              ? this.defaultImage
              : this.systemData.asset
          "
        />
        <span class="imageSubline" v-if="this.systemData.name !== ''">{{
          this.systemData.name
        }}</span>
      </div>
      <div class="w-full xl:w-1/3">
        <p>
          <label>Anwendungsbereich</label>
          <Multiselect
            ref="component"
            v-model="system.component"
            placeholder="Bitte wählen"
            :options="this.selectValues.component.values"
            :canDeselect="false"
            :canClear="false"
            @select="nextStep(2, 'component', 'surface')"
            @clear="
              resetSelects(
                ['component', 'surface', 'ground', 'insulationmaterial', 'insulation'],
                1
              )
            "
          />
        </p>
        <p>
          <label>Oberflächenbeschichtung</label>
          <Multiselect
            ref="surface"
            v-model="system.surface"
            placeholder="Bitte wählen"
            :options="this.selectValues.surface.values"
            :disabled="system.component !== null || innerStep >= 2 ? false : true"
            :searchable="false"
            :canDeselect="false"
            :canClear="false"
            @select="nextStep(3, 'surface', 'ground')"
            @clear="
              resetSelects(
                ['surface', 'ground', 'insulationmaterial', 'insulation']
              )
            "
          />
        </p>
        <p>
          <label>Untergrund</label>
          <Multiselect
            ref="ground"
            v-model="system.ground"
            placeholder="Bitte wählen"
            :options="this.selectValues.ground.values"
            :disabled="system.ground !== null || innerStep >= 3 ? false : true"
            :searchable="false"
            :canDeselect="false"
            :canClear="false"
            @select="nextStep(4, 'ground', 'insulationmaterial')"
            @clear="
              resetSelects(['ground', 'insulationmaterial', 'insulation'])
            "
          />
        </p>
        <p>
          <label>Dämmstoffmaterial</label>
          <Multiselect
            ref="insulationmaterial"
            v-model="system.insulationmaterial"
            placeholder="Bitte wählen"
            :options="this.selectValues.insulationmaterial.values"
            :canDeselect="false"
            :canClear="false"
            :disabled="
              system.insulationmaterial !== null || innerStep >= 4
                ? false
                : true
            "
            :searchable="false"
            @select="nextStep(5, 'insulationmaterial', 'insulation')"
            @clear="resetSelects(['insulationmaterial', 'insulation'])"
          />
        </p>
        <p>
          <label>Dämmstoff</label>
          <Multiselect
            ref="insulation"
            v-model="system.insulation"
            placeholder="Bitte wählen"
            :options="this.selectValues.insulation.values"
            :canDeselect="false"
            :canClear="false"
            :disabled="
              system.insulation !== null || innerStep >= 5 ? false : true
            "
            :searchable="false"
            @select="nextStep(6, 'insulation', 'thickness')"
            @clear="resetSelects(['insulation'])"
          />
        </p>
        <p>
          <label>Dämmstoffdicke in mm</label>
          <Slider
            v-model="system.thickness"
            :step="sliderOptions.step"
            :min="sliderOptions.min"
            :max="sliderOptions.max"
            :showTooltip="sliderOptions.tooltip"
            :disabled="innerStep >= 6 || system.thickness !== 0 ? false : true"
            @change="resetDowel()"
          />
          <span class="sliderInfo">
            <span>{{ this.sliderOptions.min }}</span>
            <span class="currentThickness"
              >Aktuelle Dämmstoffdicke: {{ this.system.thickness }} mm</span
            >
            <span>{{ this.sliderOptions.max }}</span>
          </span>
        </p>
      </div>
    </div>
    <div class="w-full btnWrapper">
      <button
        @click="$emit('changeTab', { currentTab: 'tab2', type: 'back' })"
        class="prevBtn"
      >
        Zurück
      </button>
      <button
        @click="$emit('changeTab', { currentTab: 'tab4', type: 'next' })"
        class="nextBtn"
        :disabled="nextClick"
      >
        Weiter
      </button>
    </div>
    <div class="mobilTabNavigation">
      <a href="#" class="tabLink">
        <span class="headline number">4</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Dübelauswahl</span>
      </a>
      <a href="#" class="tabLink">
        <span class="headline number">5</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Ergebnis</span>
      </a>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import Slider from "@vueform/slider";

export default {
  components: {
    Multiselect,
    Slider,
  },
  data() {
    return {
      innerStep: 1,
      selectValues: {},
      sliderOptions: {
        min: 40,
        max: 400,
        step: 20,
        tooltip: "focus",
      },
      currentChildren: {
        component: {},
        surface: {},
        ground: {},
        insulationmaterial: {},
        insulation: {},
      },
      stepData: {},
      defaultImage:
        "https://www.knauf.de/cutout/cutout_1109172_detail_picture_1.jpg",
      imgFolder: process.env.VUE_APP_FRONTEND_PATH + "images/",
    };
  },
  computed: {
    system: {
      get() {
        return this.$store.getters.getSystem;
      },
      set() {
        this.$store.commit("updateSystem", this.system);
      },
    },
    currentSystemChildren: {
      get() {
        return this.$store.getters.getSystemChildren;
      },
      set() {
        this.$store.commit("updateSystemChildren", this.currentChildren);
      },
    },
    nextClick() {
      let isNotValid = false;
      if (this.system.thickness === 0 || this.system.thickness === "") {
        isNotValid = true;
      }
      if (this.system.system === null || this.system.system === "") {
        isNotValid = true;
      }
      return isNotValid;
    },
    systemData() {
      let sysdata = {
        name: "",
      };
      if (this.system.system !== null) {
        sysdata = this.system.system;
      }
      return sysdata;
    },
  },
  created() {
    this.selectValues = this.$store.getters.getDefinitions;
    this.stepData = this.$store.getters.getSystemTree;
    this.currentChildren.component = this.stepData;
    if (this.currentSystemChildren.component.length !== undefined) {
      this.currentChildren = this.currentSystemChildren;
      this.innerStep = 6;
      const reloadedThicknessValues = this.findInList(
        this.selectValues.thickness.values,
        "value",
        this.currentChildren["thickness"][0].label
      );
      this.sliderOptions.min = reloadedThicknessValues.start;
      this.sliderOptions.max = reloadedThicknessValues.end;
    } else {
      this.currentSystemChildren = this.currentChildren;
    }
    for (
      let i = 0, l = this.selectValues.component.values.length;
      i < l;
      i += 1
    ) {
      const item = this.selectValues.component.values[i];
      item.disabled = false;
    }
  },
  methods: {
    resetSelects(clearArray, step) {
      for (let index = 0; index < clearArray.length; index++) {
        const element = clearArray[index];
        this.$refs[element].setPointer(false);
        this.disableOptions(this.selectValues[element].values);
        this.system[element] = null;
        for (
          let i = 0, l = this.selectValues[element].values.length;
          i < l;
          i += 1
        ) {
          const item = this.selectValues[element].values[i];
          item.disabled = false;
        }
      }
      this.sliderOptions.min = 0;
      this.sliderOptions.max = 0;
      this.system.thickness = 0;
      this.innerStep = step;
      this.currentChildren[clearArray[0]] = this.stepData;
      this.currentSystemChildren = this.currentChildren;
      this.system.system = false;
      this.resetDowel();
    },
    resetDowel() {
      this.$store.commit("resetDowel");
    },
    findInList(source, sourceKey, id) {
      for (const key in source) {
        const item = source[key];
        if (item[sourceKey] === id) {
          return item;
        }
        if (item.children) {
          const subresult = this.findInList(item.children, sourceKey, id);
          if (subresult) {
            return subresult;
          }
        }
      }
    },
    nextStep(step, _self, _next) {
      if (step < this.innerStep) {
        switch (step) {
          case 2:
            this.resetSelects(
              ["surface", "ground", "insulationmaterial", "insulation"],
              step
            );
            break;
          case 3:
            this.resetSelects(["ground", "insulationmaterial", "insulation"], step);
            break;
          case 4:
            this.resetSelects(["insulationmaterial", "insulation"], step);
            break;
          case 5:
            this.resetSelects(["insulation"], step);
            break
        }
      }

      const selectedElement = this.findInList(
        this.currentSystemChildren[_self],
        "label",
        this.system[_self]
      );
      this.currentChildren[_next] = selectedElement.children;

      if (step === 6) {
        if (this.system.thickness !== 0) {
          this.resetDowel();
        }
        const thicknessValues = this.findInList(
          this.selectValues.thickness.values,
          "value",
          this.currentChildren[_next][0].label
        );
        //Ausnahme für PU Slimtherm (in_20) und PF Slimtherm (in_19)
        if (
          this.system.insulation == "in_19" ||
          this.system.insulation == "in_20"
        ) {
          this.sliderOptions.step = 10;
        } else {
          this.sliderOptions.step = 20;
        }
        this.sliderOptions.min = thicknessValues.start;
        this.sliderOptions.max = thicknessValues.end;
        this.system.thickness = thicknessValues.start;

        this.system.system = this.currentChildren[_next][0].system;
        // console.log(this.system.system.id);
        //Hier müsste dann der Untergrund dazu
        this.$store.dispatch("getAnchorGroundSelect", [
          this.system.system.id,
          this.system.ground,
        ]);
      } else {
        this.disableOptions(this.selectValues[_next].values);
        for (let i = 0, l = this.currentChildren[_next].length; i < l; i += 1) {
          this.enableOptions(
            this.selectValues[_next].values,
            this.currentChildren[_next][i]["label"]
          );
        }
      }
      this.innerStep = step;
      this.currentSystemChildren = this.currentChildren;
    },
    disableOptions(list) {
      for (let i = 0, l = list.length; i < l; i += 1) {
        const item = list[i];
        item.disabled = true;
      }
    },
    enableOptions(list, value) {
      for (let i = 0, l = list.length; i < l; i += 1) {
        const item = list[i];
        if (item.value === value) {
          item.disabled = false;
        }
      }
    },
  },
};
</script>

<style></style>
